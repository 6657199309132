import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { mergeMap, Observable, of, throwError } from 'rxjs';
import { environment as Config } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import * as AuthActions from '../store/auth.actions';
import { OperatorDTOExtended } from '../../operators/operators.model';
import { LicenseService } from "../../license/services/license.service";

@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly http = inject(HttpClient);
  private readonly licenseService = inject(LicenseService);
  private readonly store = inject(Store);

  public login (): Observable<OperatorDTOExtended> {
    return this.http.get<OperatorDTOExtended>(`${Config.apiBaseUrl}/operator/login-new`).pipe(
      mergeMap(operator => {
        // In caso l'utente non abbia il flag attivo "canLoginTagWeb" sul ruolo genero l'eccezione per il redirect.
        if(!operator.role.canLoginTagWeb || !operator.licenseActivation?.activeFeatures?.includes('LoginWeb')) {
          return throwError(() => new HttpErrorResponse({
            error: { message: 'CannotLoginTagAppWeb' },
            status: HttpStatusCode.Forbidden,
            statusText: 'Role => CanLoginTagWeb => false'
          }));
        }
        return of(operator);
      })
    );
  }

  public logout (): void {
    this.store.dispatch(AuthActions.logout({ homePage: false }));
  }

}
